import { useState } from "react";
import useSWR from "swr";
const indexerUrl = process.env.REACT_APP_KARMA_GAP_INDEXER_URL;
export function useGap(projectId) {
  const [grants, setGrants] = useState([]);
  const getGrantsFor = async projectRegistryId => {
    if (!indexerUrl) throw new Error("GAP Indexer url not set.");
    try {
      const items = await fetch(`${indexerUrl}/grants/external-id/${projectRegistryId}`).then(res => res.json());
      if (!Array.isArray(items)) {
        setGrants([]);
        return;
      }
      const parsedItems = items.filter(grant => grant.title).map(grant => ({
        ...grant,
        milestones: grant.milestones.concat(grant.updates.map(update => ({
          uid: update.uid,
          description: update.text,
          endsAtMs: update.createdAtMs,
          title: update.title,
          isGrantUpdate: true,
          completed: update
        }))).sort((a, b) => {
          var _a$completed, _b$completed;
          const dateToCompareA = ((_a$completed = a.completed) === null || _a$completed === void 0 ? void 0 : _a$completed.createdAtMs) || a.endsAtMs;
          const dateToCompareB = ((_b$completed = b.completed) === null || _b$completed === void 0 ? void 0 : _b$completed.createdAtMs) || b.endsAtMs;
          return dateToCompareB - dateToCompareA;
        })
      })).sort((a, b) => b.createdAtMs - a.createdAtMs) || [];
      setGrants(parsedItems);
    } catch (e) {
      console.error(`No grants found for project: ${projectRegistryId}`);
      console.error(e);
      setGrants([]);
    }
  };
  const {
    isLoading
  } = useSWR(`${indexerUrl}/grants/external-id/${projectId}`, {
    fetcher: async () => projectId && getGrantsFor(projectId)
  });
  return {
    /**
     * Fetch GAP Indexer for grants for a project
     * @param projectRegistryId registryId
     */
    getGrantsFor,
    /**
     * Grants for a project (loaded from GAP)
     */
    grants,
    isGapLoading: isLoading
  };
}
export const gapAppUrl = `${process.env.REACT_APP_KARMA_GAP_APP_URL}`;
export const getGapProjectUrl = (projectUID, grantUID) => `${gapAppUrl}/project/${projectUID}/${grantUID ? `?tab=grants&grant=${grantUID}` : ""}`;